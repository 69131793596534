import "../core/src/modules/peek-view/view/modal-container.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/peek-view/view/modal-container.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VU227bMAx9z1fwZUACRIHcpG2qYED/YxgGxaIdLrLkyXLidOi/D5bt1G6uxR4M6EIenkMf8XWLh8TJDAv4FWV/DBZuDn9HAImzWVgAeCdNkViXCShiqXHMZxGfrEYA7yMAby+FRW3M++j1tMoiZB2TbS5j8gcBfHVHzR65Y2J0IfEqi8fPWoc83s9SjK4APl0EjC4D8g5QiB3hngUN5Mkaljpb5uMO/WUSUqWhTIZrVbqwELCTbsxYF1i36hycwf1lMCMzFB+tOY9gtboX4ek8wlDS82R6M2b5Fdn9uIS0ZplVKCCxbi+dKoYBnjIyKUtKEzd4cbmmmK3xjdCN+WzxMAU+BT57XE4huqOpz9dbMq/LHy3KrKOUTlRcqtNv/Y06i/+p09dz0vovm+QGQjDJrNvJEJvbBkdAQhWqWguZAn37Ut4YGYVVJ0cmCRlk7Wn9u6UOPljLeFsbySgWW23dp4S1lvGWzRvPnPRgQPJlFViR8egY7tD4QoAsvR2QX99DXlGRa3kQkGisghk1pYaRx6wQEGNdoj7+XRaekgOLrfFofP/qPv09YnEgdr1yfcgKL52vr/ak/EZAxPm3ertBSje+2/eQVUC+3rznQQbekbEcZCQho2X0MiTUbjNZsY7yw2KZVwOAtJnJum5ZdCrntlE+ru0OnZYHlkuDugk+Wk05m9cTx6MTEDbFRiq7H/O8gvp7yCtw6VqOeTNU6rnCl5MGwDqFjjmpqCwEBAkAGRnWkZ1z3hza0msyKMBYg6uB4xxq6WmHQ/k/lPSSGcuCf2WYdN+9K/Fna9ihrxvYHsDm2D9WbByZ7ed3yKKObPsTFi3/XCpFJg16gAOH6Km9OP+W/gGAnxJ7jggAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var animationTimeout = 'var(--_1mqnesr0)';
export var animationType = 'var(--_1mqnesr2)';
export var containerPadding = '_1mqnesrf';
export var modalContent = '_1mqnesrg';
export var modalContentContainer = '_1mqnesrc';
export var modalContentContainerWithFade = '_1mqnesre';
export var modalContentContainerWithZoom = '_1mqnesrd';
export var modalContentWrapper = '_1mqnesrb';
export var modalControls = '_1mqnesrh';
export var modalOverlay = '_1mqnesra';
export var transformOrigin = 'var(--_1mqnesr1)';